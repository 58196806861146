import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, 20.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path d="M 67.764 42.656 L 58.682 26.777 L 54.463 31.406 L 54.463 42.656 L 44.18 42.656 L 44.18 0 L 54.463 0 L 54.463 18.809 L 58.037 13.389 L 67.236 0 L 79.951 0 L 65.537 18.867 L 79.951 42.656 L 67.764 42.656 Z M 30.029 42.656 L 27.539 34.688 L 13.447 34.688 L 10.986 42.656 L 0 42.656 L 15.645 0 L 25.313 0 L 41.074 42.656 L 30.029 42.656 Z M 20.479 11.953 L 15.908 26.748 L 25.078 26.748 L 20.479 11.953 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;